@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700&display=swap');
$mukta: 'Mukta', sans-serif;
$kalimati: 'Kalimati', sans-serif;

h1,
h2,
h3,
h4,
h5,
p {
	margin: 0;
}
.textCapitalize {
	text-transform: capitalize;
}
.textUppercase {
	text-transform: uppercase;
}

h2 {
	color: #6777ef;
}
