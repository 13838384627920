.modal_main {
  .inputs_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .code_input {
      width: 40%;
    }
  }
  .description_input{
      width: 80% ;
  }
}
