.login-brand {
  color: #666;
  text-align: center;
  img {
    margin-bottom: 10px;
  }
  h3 {
    color: $red-shade;
  }
  p {
    color: $primary;
  }
}
.card .card-header h3 {
  font-size: 16px;
  line-height: 28px;
  color: #6777ef;
  padding-right: 10px;
  margin-bottom: 0;
}
.text-small {
  font-size: 12px;
  line-height: 20px;
}
.simple-footer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    color: grey;
    font-weight: bold;
  }
}
.form-group .control-label,
.form-group > label {
  font-weight: 600;
  color: #34395e;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.powered-by {
  position: relative;
  margin-bottom: 18px;
  p {
    width: max-content;
    padding-inline: 0.5rem;
    margin-inline: auto;
    background-color: #f3f7fa;
    position: relative;
    z-index: 1;
    color: #424242 !important;
    font-size: 14px;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 50%;
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
  }
}
.powered {
  height: 60px;
  margin-block-end: 21px;
}
.simple-footer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  * {
    color: #424242;
  }
  a {
    text-decoration: underline !important;
  }
}
.login-page .btn {
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  padding-block: 0.5rem;
  width: 100%;
  font-size: 16px;
}
.login-page .btn-black {
  background-color: #404040;
  color: #fff;
  font-size: 1.1rem;
}

.login-heading{
  padding-block: 64px;

  h4 {
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 16.8px!important;
    color: #ef2b24;
  }
  h5{
    font-weight: 600!important;
    font-size: 18px!important;
    line-height: 19.2px!important;
    color: #ef2b24;
    margin: 0!important;
  }
}

.login-page .login-logo {
  width: 150px!important;
  display: grid;
  place-content: center;

  img{
    width: 100px;
  }
}