.kawa_active {
  background: #6171e4 !important;
  color: white;
  td {
    color: white;
  }
  .kawa_indicator {
    color: #6171e4;
    background-color: white;
    padding: 0.1rem 0.5rem;
    margin-right: 0.4rem;
    border-radius: 5px;
    svg {
      margin-right: 0.3rem;
    }
  }
}
