.document-img-sm {
	width: 70px;
	height: 70px;
	object-fit: cover;
	border-radius: 4px;
}
.scroll {
	height: 70vh;
	overflow: auto;
}
.img-wrapper {
	margin-right: 20px;
	p {
		font-size: 12px;
	}
}
.document-scroll {
	height: 48vh;
	overflow: auto;
}
.document-img-lg {
	width: 100%;
}

.citizen-status-check {
	.container-fluid {
		margin-inline: auto;
	}
	&-container {
		padding-top: calc(86px + 2rem);
		position: relative;
	}
	&-back-button {
		position: absolute;
	}
	&-status {
		padding: 0 !important;
	}

	.card {
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
		width: 40%;
		margin-inline: auto;
		margin-block-end: 0;
		padding: 2rem;
		.card-body {
			padding: 0;
			h4 {
				color: #242424 !important;
				font-weight: 600;
				font-size: 20px;
			}
			p {
				color: #6d6d6d;
				font-weight: 400;
				text-align: center;
			}
			.status-check-input {
				border: 1px solid #d0d0d0;
				border-radius: 0.5rem;
				font-size: 14px !important;
				padding: 0.5rem;
				color: #929292;
				flex-grow: 1;
				margin-right: 0.5rem;
			}
			.btn-status-check {
				border-radius: 0.5rem;
				background-color: #6677ef;
				font-size: 12px;
				font-weight: 600;
				padding-block: 0.5rem;
				padding-inline: 1rem;
				border: 1px solid transparent;
				color: white;
			}
		}
	}
	&-logo {
		width: 65px;
		margin-bottom: 10px;
	}

	.status-result-card {
		padding-top: 0;
		border: none;
		&-info {
			p:not(:last-child) {
				border-bottom: 1px solid #b6b6b6;
			}
			p {
				display: flex;
				padding-block: 0.5rem;
				* {
					font-size: 14px;
					font-weight: 500;
				}
				strong {
					text-align: start;
					width: 25%;
					color: #6d6d6d;
				}
				span {
					color: #494949;
				}
				.status-badge {
					border-radius: 12px;
					padding-inline: 0.5rem;
					padding-block: 0.25rem;
					font-size: 12px;
					display: flex;
					align-items: center;
					.status-dot {
						margin-inline-end: 0.25rem;
					}
				}
				.status-approved {
					color: #25a25a;
					background-color: #e2f8eb;
				}
				.status-rejected {
					color: #de2115;
					background-color: #fde8e8;
				}
			}
		}
	}
}

.citizen-sifaris-verification {
	.container-fluid {
		width: 90%;
		margin-inline: auto;
	}
	.card {
		width: 33%;
		margin: 0;
		margin-inline: auto;
		border: none;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
		.card-body {
			padding: 2rem;
			img {
				width: 65px;
			}
			h4 {
				text-align: center !important;
				color: #242424;
				font-weight: 600;
				font-size: 20px;
				margin-block-end: 0.25rem;
			}
			p[datatype='verification-check'] {
				color: #6d6d6d;
				font-size: 16px;
				font-weight: 400;
				text-align: center;
				line-height: 25.6px;
				padding-inline: 20px;
			}
		}
	}

	.status-verification-card {
		.footer-address {
			font-size: 1rem;
			color: #6d6d6d;
			margin-block-end: 20px;
		}
		.more-info {
			font-size: 1rem;
			font-weight: 600;
			color: #242424;
		}
	}
}

.status-check-input {
	border: 1px solid #d0d0d0;
	border-radius: 0.5rem;
	font-size: 14px !important;
	padding-block: 0.5rem !important;
	padding-inline: 1rem !important;
	color: #929292;
	flex-grow: 1;
	margin-right: 0.5rem;
}
.btn-status-check {
	border-radius: 0.5rem;
	background-color: #6677ef;
	font-size: 12px;
	font-weight: 600;
	padding-block: 0.5rem;
	padding-inline: 1rem;
	border: 1px solid transparent;
	color: white;
}
