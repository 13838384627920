.header {
  background-color: $primary;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: #6777ef;
  z-index: 1;
  padding-left: 220px;
  padding-right: 30px;
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    height: 70px;
    .header-right {
      display: flex;
      align-items: center;
      .header-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
      }
      p {
        color: #fff;
        font-weight: 500;
        margin-bottom: 0;
      }
      h5 {
        color: #fff;
        font-weight: 400;
        margin-left: 30px;
      }
    }
  }
}
