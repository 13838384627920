.sidebar-wrapper {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
	width: 220px;
	background-color: #fff;
	position: fixed;
	top: 0;
	z-index: 880;
	left: 0;
	height: 100%;
	overflow: auto;

	/* width */
	&::-webkit-scrollbar {
		width: 5px;
		margin: 5px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #c8e7e8;
		border-radius: 2.5px;
		height: 20%;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		padding: 0px 5px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #6777ef;
	}

	.sidebar-top {
		padding: 20px;
		text-align: center;
		.logo {
			width: 80px;
			margin-bottom: 10px;
		}
		h4 {
			text-decoration: none;
			text-transform: uppercase;
			font-weight: 700;
			color: #000;
			margin-bottom: 20px;
		}
	}
}
.menu-items {
	display: flex;
	align-items: center;

	span {
		margin-left: 14px;
		font-weight: 500;
		font-size: 14px;
	}
}

.templateSubNav {
	background: #f0f0f0;
	border-bottom: 1px solid white;

	&:hover {
		background-color: #f3f3f3;
	}
	&.navbar__link--active {
		// background: #fbfbfb;
		font-weight: bold;
		background-color: #f5f5f5;
		color: #6677ef !important;
		opacity: 1;
	}
}
