.citizenHeaderStyles {
  .login-register {
    height: 100vh;
    padding: 10% 5%;
  }
  .login-box {
    width: 600px;
    margin: 0 auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border-top: 3px solid #1f5fba;
    border-bottom: 3px solid #d1010d;
  }
  @media screen and (max-width: 600px) {
    .login-box {
      width: 100%;
    }
  }
  .logo {
    height: 80px;
  }

  /* Header */
  .header {
    background-color: #6677ef;
    left: 0;
    width: 100%;
    height: 70px;
    padding: 0;
  }
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding-left: 0;
  }
  .header-left {
    display: flex;
    align-items: center;
  }
  .header-right {
    display: flex;
    align-items: center;
  }
  .main-content {
    margin-top: 30px;
  }
  .custom-card {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border: none;
    text-align: center;
    margin-bottom: 30px;
  }
  .logo-sm {
    width: 60px;
    margin-right: 10px;
  }
}
