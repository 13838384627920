.template-card-popover {
  .content {
    max-height: 240px;
    overflow-y: auto;

    .detail {
      font-size: 15px;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ececec;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #6777ef;
      border-radius: 10px;
    }
  }
}

.template-card {
  a {
    color: inherit;
  }
  .i-icon {
    transition: transform 120ms;
    &:hover {
      transform: scale(1.1);
    }
  }
  .tempalte-card-content {
    .tempate-card-icon-container {
      width: 56px;
      margin-right: 18px;
      background: #f4f6f9;
      padding: 5px;
      border-radius: 1000px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
}
