// Colors
$primary: #6677ef;
$primary-200: #8e9af3;
$primary-100: #bbc2f8;
$secondary: #333333;
$light: #707070;
$xlight: #f4f6f9;
$info: #385ada;
$danger: #ef2b24;
$success: #0c8a72;
$warning: yellow;
$white: #fff;
$dark: #333;
$purple-shade: #7f69ef;
$orange-shade: #fc7152;
$yellow-shade: #fdb20a;
$green-shade: #49aa83;
$blue-shade: #6590d4;
$sky-shade: #20cfd0;
$red-shade: #ad4848;

$box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
$box-shadow-dark: 0 0 6px 0 rgba(0, 0, 0, 0.5);
$box-shadow-light: 0 0 6px 2px rgba(255, 255, 255, 0.5);
